import axiosApi from '@/helpers/axios'
import i18n from '@/i18n'
import serializerUtils from './serializer_utils'

var submitApiServices = {
  async getNewAssessment ({ widgetUuid, assessmentTypeUuid, originUrlAsBase64 }) {
    const baseUrl = process.env.VUE_APP_API_ASSESSMENTS_URL
    const endpoint = 'public-assessments/new_assessment'

    let queryParamsList = []

    if (widgetUuid) { queryParamsList.push(`widget_uuid=${widgetUuid}`) }
    if (assessmentTypeUuid) { queryParamsList.push(`assessment_type_uuid=${assessmentTypeUuid}`) }
    if (originUrlAsBase64) { queryParamsList.push(`origin_url_as_base64=${originUrlAsBase64}`) }

    let queryParams = ''
    if (queryParamsList) { queryParams = '?' + queryParamsList.join('&') }

    const response = await axiosApi.get(`${baseUrl}/${endpoint}/${queryParams}`)
    const assessmentUuid = response.data.data.attributes.uuid
    return assessmentUuid
  },

  shareLinkWithEmail ({ widgetUuid, assessmentUuid, email }) {
    const request = {
      data: {
        type: 'assessments',
        id: assessmentUuid,
        attributes: {
          uuid: assessmentUuid,
          email: email,
          widget_uuid: widgetUuid,
        },
      },
    }
    const endpoint = 'public-assessments/manually_trigger_journey_to_share_link_via_email/'
    return axiosApi.post(`${process.env.VUE_APP_API_ASSESSMENTS_URL}/${endpoint}`, request)
  },

  submitEmail ({ assessmentUuid, email }) {
    const request = {
      data: {
        type: 'assessments',
        id: assessmentUuid,
        attributes: {
          uuid: assessmentUuid,
          email: email,
        },
      },
    }
    const endpoint = 'public-assessments/submit_email/'
    return axiosApi.post(`${process.env.VUE_APP_API_ASSESSMENTS_URL}/${endpoint}`, request)
  },

  submitFinal ({
    assessmentUuid,
    isInformedConsentGiven,
    topicOfInterestUuid,
    serializedUserResponses,
    widgetUuid,
    nrImagesTaken,
    nrImagesPromptedToTake,
    clinicUuid = null,
    groupUuids = null,
  }) {
    // NOTE: Current implementation of the backend has the arguments
    //   `clinic_uuid` and `group_uuids` as optional. It is only
    //   used in the "advanced" feature of e.g. a large clinic wishing
    //   to allow a single web app to route to specific apps. It considered
    //   an edge-case.
    const request = {
      data: {
        type: 'assessments',
        id: assessmentUuid,
        attributes: {
          uuid: assessmentUuid,
          topic_of_interest_uuid: topicOfInterestUuid,
          is_informed_consent_given: isInformedConsentGiven,
          widget_uuid: widgetUuid,
          clinic_uuid: clinicUuid,
          group_uuids: groupUuids,
          nr_images_taken: nrImagesTaken,
          nr_images_prompted_to_take: nrImagesPromptedToTake,
        },
        relationships: {
          user_responses: {
            data: serializedUserResponses,
          },
        },
      },
    }
    const endpoint = 'public-assessments/submit/'
    return axiosApi.post(`${process.env.VUE_APP_API_ASSESSMENTS_URL}/${endpoint}`, request)
  },

  retrievePreviouslyStartedAssessment ({ assessmentUuid }) {
    const request = {
      data: {
        type: 'assessments',
        id: assessmentUuid,
        attributes: {
          uuid: assessmentUuid,
        },
      },
    }
    const endpoint = 'public-assessments/get_previously_nonsubmitted_assessment_input/'
    return axiosApi.post(`${process.env.VUE_APP_API_ASSESSMENTS_URL}/${endpoint}`, request)
  },

  saveLatestStateToPersistentStore ({ assessmentUuid, userResponses }) {
    /** Saves latest state to a persistent store that can be retrieved
     *   if an assessment is resumed. Currently stores to main database.
     *
     *  IMPORTANT: This is client-driven, and no specific format is expected
     *   other than JSON (although: Try to keep it as flat key/value pairs).
    */
    const latestState = userResponses // Update as needed
    const request = {
      data: {
        type: 'assessments',
        id: assessmentUuid,
        attributes: {
          uuid: assessmentUuid,
          latest_funnel_widget_state_as_json: latestState,
        },
      },
    }
    const endpoint = 'public-assessments/save_latest_nonsubmitted_assessment_input/'
    return axiosApi.post(`${process.env.VUE_APP_API_ASSESSMENTS_URL}/${endpoint}`, request)
  },

  // manuallyTriggerEmailAutomationJourney ({ widgetUuid, assessmentUuid, email, name, phoneNumber }) {
  //   const request = {
  //     data: {
  //       type: 'assessments',
  //       id: assessmentUuid,
  //       attributes: {
  //         widget_uuid: widgetUuid,
  //         uuid: assessmentUuid,
  //         email: email,
  //         name: name,
  //         phone_number: phoneNumber,
  //       },
  //     },
  //   }
  //   const endpoint = 'public-assessments/manually_trigger_email_automation_journey/'
  //   return axiosApi.post(`${process.env.VUE_APP_API_ASSESSMENTS_URL}/${endpoint}`, request)
  // },

  serializeFunnelUserResponses ({
    email,
    emailQuestion,
    name,
    nameQuestion,
    phone,
    phoneQuestion,
    year,
    yearQuestion,
    socialSecurityNumber,
    socialSecurityNumberQuestion,
    selectedUserIntents,
    userIntentsQuestion,
    primaryGoal,
    primaryGoalQuestion,
    feedback,
    feedbackQuestion,
  }) {
    const serializedUserResponses = []
    const _email = serializerUtils.serializeUserResponse({
      topicRaw: 'email',
      questionRaw: emailQuestion,
      responseRaw: email,
      inputType: 'free-text',
      topicUuid: null,
      topicName: null,
      responseOptionUuid: null,
    })
    serializedUserResponses.push(_email)

    const _name = serializerUtils.serializeUserResponse({
      topicRaw: 'name',
      questionRaw: nameQuestion,
      responseRaw: name,
      inputType: 'free-text',
      topicUuid: null,
      topicName: null,
      responseOptionUuid: null,
    })
    serializedUserResponses.push(_name)

    const _phone = serializerUtils.serializeUserResponse({
      topicRaw: 'phone',
      questionRaw: phoneQuestion,
      responseRaw: phone,
      inputType: 'free-text',
      topicUuid: null,
      topicName: null,
      responseOptionUuid: null,
    })
    serializedUserResponses.push(_phone)

    const _year = serializerUtils.serializeUserResponse({
      topicRaw: 'year',
      questionRaw: yearQuestion,
      responseRaw: year,
      inputType: 'free-text',
      topicUuid: null,
      topicName: null,
      responseOptionUuid: null,
    })
    serializedUserResponses.push(_year)

    if (socialSecurityNumber !== null) {
      const _socialSecurityNumber = serializerUtils.serializeUserResponse({
        topicRaw: 'social-security-number',
        questionRaw: socialSecurityNumberQuestion,
        responseRaw: socialSecurityNumber,
        inputType: 'free-text',
        topicUuid: null,
        topicName: null,
        responseOptionUuid: null,
      })
      serializedUserResponses.push(_socialSecurityNumber)
    }

    if (feedback !== null) {
      const _feedback = serializerUtils.serializeUserResponse({
        topicRaw: 'optional-feedback',
        questionRaw: feedbackQuestion,
        responseRaw: feedback,
        inputType: 'free-text',
        topicUuid: null,
        topicName: null,
        responseOptionUuid: null,
      })
      serializedUserResponses.push(_feedback)
    }

    let i = 0
    for (i in selectedUserIntents) {
      const _intent = selectedUserIntents[i]
      const _text = _intent.text
      const intent = serializerUtils.serializeUserResponse({
        topicRaw: 'topics-of-interest',
        questionRaw: userIntentsQuestion,
        responseRaw: _text,
        inputType: 'multi-choice',
        topicUuid: null,
        topicName: null,
        responseOptionUuid: null,
      })
      serializedUserResponses.push(intent)
    }

    const _primaryGoal = serializerUtils.serializeUserResponse({
      topicRaw: 'primary-goal',
      questionRaw: primaryGoalQuestion,
      responseRaw: primaryGoal,
      inputType: 'free-text',
      topicUuid: null,
      topicName: null,
      responseOptionUuid: null,
    })
    serializedUserResponses.push(_primaryGoal)

    return serializedUserResponses
  },

  serializeAnamnesisUserResponses ({
    mappedUserResponses,
  }) {
    const placeholderText = i18n.t('QUESTION_GENERIC_NOT_ANSWERED_TEXT')
    const serializedUserResponses = []
    let i = 0
    for (i in mappedUserResponses) {
      const item = mappedUserResponses[i]
      let response = item.response
      if (!response) {
        response = placeholderText
      }
      const serializedItem = serializerUtils.serializeUserResponse({
        // NOTE: Easy caveat: Values are camelCase because this not the final
        //   serialized resource. It is arguments to `serializeUserResponse`
        topicRaw: item.topic,
        questionRaw: item.question,
        responseRaw: response,
        inputType: item.inputType,
        topicUuid: item.topicUuid,
        topicName: item.topicName,
        responseOptionUuid: item.responseOptionUuid,
      })
      serializedUserResponses.push(serializedItem)
    }
    return serializedUserResponses
  },
}

export default submitApiServices
