var serializerUtils = {
  serializeUserResponse ({
    topicRaw,
    questionRaw,
    responseRaw,
    inputType,
    responseOptionUuid,
    topicUuid,
    topicName,
  }) {
    return {
      type: 'user-responses',
      attributes: {
        topic: topicRaw,
        question: questionRaw,
        response: responseRaw,
        input_type: inputType,
        response_option_uuid: responseOptionUuid,
        topic_uuid: topicUuid,
        topic_name: topicName,
      },
    }
  },
}

export default serializerUtils
